import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import qs from 'qs'
import classNames from 'classnames'

import SEO from '../components/seo'
import Layout from '../components/layout'
import FormField from '../components/FormField'

const LOGIN_URL = 'https://webapp.mediterraneaexpress.com/webapi/login.aspx'
const REDIRECT_URL = 'https://webapp.mediterraneaexpress.com/default_normale.aspx'

const Login = () => {
  const { handleSubmit, register, errors } = useForm()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleLogin = handleSubmit(({ email, password }) => {
    setLoading(true)
    setError('')

    axios
      .post(
        LOGIN_URL,
        qs.stringify({
          email,
          password,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((res) => {
        if (res.data && res.data.token) {
          window.location = `${REDIRECT_URL}?t=${encodeURIComponent(
            res.data.token
          )}`
        } else {
          setLoading(false)
          setError('Email o password errate')
        }
      })
      .catch((error) => {
        setLoading(false)
        setError(`E' avvenuto un errore durante la comunicazione con il server`)
      })
  })

  return (
    <Layout>
      <SEO
        title="Accesso area clienti"
        description="Login area riservata Mediterranea Express"
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight text-center">
          Accedi all'area riservata
        </h1>
      </section>
      <section className="py-8 px-10 bg-white lg:rounded-lg shadow-xl max-w-sm mx-auto">
        <h2 className="text-center text-gray-600 mb-6">
          Accedi a Mediterranea Express
        </h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <FormField
              fieldName="email"
              label="Email"
              type="email"
              register={register}
              validation={{ required: `Inserisci l'indirizzo e-mail` }}
              error={errors.email}
            />
          </div>
          <div className="mb-6">
            <FormField
              fieldName="password"
              label="Password"
              type="password"
              register={register}
              validation={{ required: 'Inserisci la password' }}
              error={errors.password}
            />
          </div>
          <button
            className={classNames(
              'block w-full focus:shadow-outline focus:outline-none text-white font-semibold leading-5 py-3 px-5 rounded bg-blue-600 hover:bg-blue-700 transition-colors duration-150',
              { 'opacity-50': loading }
            )}
            type="submit"
            disabled={loading}
          >
            Accedi
          </button>
          {error && (
            <div className="text-red-600 text-sm mt-6 leading-tight pl-3 border-l-4 border-red-600">
              {error}
            </div>
          )}
        </form>
      </section>
      <section className="py-8 px-6 text-center">
        <a
          href="https://webapp.mediterraneaexpress.com"
          className="text-gls-blue-600 hover:text-gls-blue-900 hover:underline"
        >
          Accesso operatori
        </a>
      </section>
    </Layout>
  )
}

export default Login
